:root {
  --primary-color: rgba(38, 155, 215, 1);
  --secondary-color: rgba(222, 4, 124, 1);
  --box-shadow-default: rgba(0, 0, 0, 0.25) 0px 10px 18px, rgba(0, 0, 0, 0.22) 0px 5px 5px;
  --mobile-padding: 1.5rem;
  --border-radius: 8px;
  --standard-transition: all 0.3s ease;
}

.home-sec {
  display: flex;
  width: 100%;
  margin-top: 2rem;
  height: 90vh;
  justify-content: space-between;
  align-items: center;
}

.subHeading {
  font-size: 4.5rem;
  line-height: 1;
  background: black;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-sec .home-cnt {
  width: 70%;
  padding: 0 5rem;
  text-align: justify;
  font-size: 18px;
}

.home-cnt h2 {
  margin: 2rem 0;
}

.home-img img {
  width: 25rem;
  margin-right: 60px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-default);
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@keyframes up-down {
  0% { transform: translateY(10px); }
  100% { transform: translateY(-10px); }
}

.home-btn {
  margin: 2rem 0;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
}

/* News section styles */
.latest-news-sec {
  padding: 3rem 5rem;
  background-color: #f5f5f5;
}

.latest-news-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  background: -webkit-linear-gradient(var(--primary-color) 15%, var(--secondary-color) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.latest-news-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.latest-news-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow-default);
  transition: var(--standard-transition);
  cursor: pointer;
}

.latest-news-item:hover {
  transform: translateY(-15px);
}

.latest-news-item img {
  width: 100%;
  height: 280px;
  object-fit: cover;
  object-position: center;
}

.latest-news-details {
  padding: 1.5rem;
}

.latest-news-details h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.1rem;
  color: #333;
}

.latest-news-details p {
  margin: 0;
  color: #666;
  font-size: 0.9rem;
}

/* Modal styles */
.news-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  animation: fadeIn 0.3s ease;
}

.news-modal {
  background: white;
  border-radius: var(--border-radius);
  width: 98%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  animation: slideIn 0.3s ease;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  background: var(--primary-color);
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  color: white;
  z-index: 1;
  transition: var(--standard-transition);
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  background: var(--secondary-color);
  transform: rotate(90deg);
}

.modal-content {
  display: flex;
  padding: 30px;
  gap: 30px;
}

.modal-image {
  flex: 1;
  max-width: 50%;
  display: flex;
  align-items: center;
}

.modal-image img {
  width: 100%;
  max-height: 70vh;
  object-fit: contain;
  border-radius: var(--border-radius);
}

.modal-details {
  flex: 1;
  padding: 20px 0;
}

.modal-details h2 {
  margin-bottom: 10px;
  color: #333;
  font-size: 24px;
}

.modal-date {
  color: #666;
  margin-bottom: 20px;
  font-size: 14px;
}

.modal-description {
  white-space: pre-line;
  line-height: 1.6;
  color: #444;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* Mobile responsiveness */
@media only screen and (max-width: 600px) {
  .home-sec {
    display: block;
    height: auto;
    padding: var(--mobile-padding);
  }

  .subHeading {
    font-size: 2.5rem;
  }

  .home-sec .home-cnt {
    width: 100%;
    padding: 5rem 0 0;
    font-size: 15px;
  }

  .home-img img {
    display: block;
    margin: 2rem auto;
    width: 100%;
    max-width: 20rem;
    height: auto;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  }

  .latest-news-sec {
    padding: 2rem 1rem;
  }

  .latest-news-title {
    font-size: 1.8rem;
    text-align: center;
  }

  .latest-news-items {
    grid-template-columns: 1fr;
  }

  .latest-news-item:hover {
    transform: translateY(-5px);
  }

  .latest-news-item img {
    width: 100%;
    height: auto;
    max-height: 220px;
    object-fit: contain;
  }

  .latest-news-details {
    padding: 1rem;
  }

  .latest-news-details h3 {
    font-size: 1rem;
  }

  .modal-content {
    flex-direction: column;
    padding: 60px 20px 20px;
    gap: 5px;
  }

  .modal-image {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .modal-image img {
    width: 100%;
    max-height: 50vh;
    object-fit: contain;
  }

  .close-button {
    width: 28px;
    height: 28px;
    font-size: 18px;
    top: 15px;
    right: 15px;
  }
}